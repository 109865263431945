import { FixedWidthAndPosition, FixedHeightAndPosition } from 'types';

/*
マイページの
左カラムのユーザー情報の上に表示されるアバターパーツの画像の大きさと位置を管理する定数
*/

// 目
export const eyeAppearanceInMypage: FixedWidthAndPosition[] = [
  { width: 60, top: 108, left: 114 },
  { width: 62, top: 108, left: 114 },
  { width: 60, top: 108, left: 114 },
  { width: 60, top: 106, left: 114 },
  { width: 60, top: 110, left: 114 },
  { width: 62, top: 110, left: 114 },
  { width: 54, top: 108, left: 116 },
  { width: 62, top: 108, left: 114 },
  { width: 60, top: 108, left: 116 },
  { width: 64, top: 108, left: 114 },

  { width: 60, top: 111, left: 114 },
  { width: 60, top: 110, left: 114 },
  { width: 60, top: 108, left: 114 },
  { width: 60, top: 107, left: 114 },
  { width: 60, top: 107, left: 114 },
  { width: 60, top: 107, left: 114 },
  { width: 54, top: 108, left: 116 },
  { width: 54, top: 108, left: 116 },
  { width: 54, top: 106, left: 114 },
  { width: 54, top: 104, left: 121 },

  { width: 60, top: 108, left: 114 },
  { width: 60, top: 108, left: 114 },
  { width: 52, top: 108, left: 114 },
  { width: 56, top: 107, left: 112 },
  { width: 60, top: 110, left: 112 },
  { width: 60, top: 108, left: 112 },
  { width: 60, top: 110, left: 112 },
  { width: 60, top: 108, left: 112 },
  { width: 60, top: 114, left: 114 },
  { width: 60, top: 111, left: 114 },

  { width: 60, top: 108, left: 114 },
  { width: 60, top: 114, left: 114 },
  // 以下有料プロダクト
  { width: 52, top: 108, left: 114 },
  { width: 94, top: 98, left: 107 },
  { width: 96, top: 101, left: 106 },
  { width: 48, top: 114, left: 118 },
  { width: 52, top: 116, left: 118 },
  { width: 40, top: 114, left: 120 },
  { width: 60, top: 108, left: 113 },
  { width: 76, top: 97, left: 108 },

  { width: 86, top: 97, left: 108 },
  { width: 92, top: 100, left: 108 },
  { width: 60, top: 110, left: 114 },
  { width: 60, top: 110, left: 114 },
  { width: 64, top: 112, left: 114 },
  { width: 58, top: 110, left: 114 },
  { width: 49, top: 100, left: 116 },
  { width: 53, top: 110, left: 114 },
  { width: 56, top: 112, left: 114 },
  { width: 52, top: 114, left: 114 },

  { width: 44, top: 107, left: 116 },
  { width: 52, top: 114, left: 114 },
  { width: 52, top: 110, left: 114 },
  { width: 54, top: 112, left: 114 },
  { width: 88, top: 110, left: 110 },
  { width: 50, top: 98, left: 115 },
  { width: 40, top: 98, left: 119 },
  { width: 40, top: 114, left: 119 },
  { width: 48, top: 110, left: 118 },
  { width: 52, top: 115, left: 116 },

  { width: 60, top: 108, left: 111 },
  { width: 60, top: 113, left: 112 },
  { width: 61, top: 111, left: 112 },
  { width: 58, top: 109, left: 114 },
  { width: 60, top: 109, left: 106 },
  { width: 56, top: 112, left: 113 },
  { width: 40, top: 112, left: 118 },
  { width: 54, top: 114, left: 114 },
  { width: 54, top: 110, left: 114 },
  { width: 56, top: 114, left: 114 },

  { width: 56, top: 118, left: 114 },
  { width: 58, top: 108, left: 112 },
  { width: 56, top: 114, left: 112 },
  { width: 54, top: 112, left: 114 },
  { width: 54, top: 108, left: 114 },
  { width: 50, top: 110, left: 116 },
  { width: 50, top: 110, left: 116 },
  { width: 56, top: 112, left: 116 },
  { width: 56, top: 114, left: 116 },
  { width: 50, top: 110, left: 116 },
  { width: 60, top: 101, left: 116 },
  { width: 50, top: 107, left: 116 },
  { width: 50, top: 106, left: 116 },
  { width: 50, top: 102, left: 121 },
  { width: 42, top: 102, left: 124 },
  { width: 60, top: 108, left: 117 },
  { width: 59, top: 108, left: 118 },
  { width: 59, top: 108, left: 118 },
  { width: 59, top: 108, left: 118 },
  { width: 62, top: 109, left: 117 },
  
  { width: 62, top: 109, left: 117 },
  { width: 62, top: 109, left: 117 },
  { width: 62, top: 109, left: 117 },
  { width: 62, top: 105, left: 117 },
  { width: 62, top: 109, left: 117 },

  { width: 62, top: 109, left: 117 },
  { width: 62, top: 109, left: 117 },
  { width: 62, top: 109, left: 117 },
  { width: 62, top: 105, left: 117 },
  { width: 62, top: 109, left: 117 },

  { width: 62, top: 109, left: 117 }, //01
  { width: 62, top: 109, left: 117 },
  { width: 49, top: 110, left: 122 },
  { width: 85, top: 97, left: 110 },
  { width: 62, top: 109, left: 117 },

  { width: 103, top: 105, left: 109 }, //  目 106
  { width: 68, top: 109, left: 115 },
  { width: 68, top: 109, left: 115 },
  { width: 85, top: 97, left: 113 },
  { width: 75, top: 109, left: 112 },

  { width: 65, top: 106, left: 118 }, //  目 111
  { width: 60, top: 104, left: 118 },
  { width: 60, top: 108, left: 118 },
  { width: 60, top: 108, left: 118 },
  { width: 66, top: 108, left: 114 },

  { width: 65, top: 106, left: 118 }, //  目 116
  { width: 87, top: 70, left: 110 },
  { width: 68, top: 103, left: 114 },
  { width: 68, top: 103, left: 114 },
  { width: 68, top: 103, left: 114 },

  { width: 65, top: 106, left: 118 }, //  目 121
  { width: 65, top: 106, left: 118  },
  { width: 65, top: 106, left: 118  },
  { width: 65, top: 106, left: 118  },
  { width: 65, top: 112, left: 118  },

  { width: 65, top: 106, left: 118 }, //  目 126
  { width: 65, top: 106, left: 118  },
  { width: 65, top: 112, left: 118  },
  { width: 73, top: 110, left: 114  },
  { width: 65, top: 112, left: 118  },

  { width: 96, top: 83, left: 98 }, // 目 131
  { width: 63, top: 106, left: 114 },
  { width: 65, top: 107, left: 115 },
  { width: 53, top: 113, left: 120 },
  { width: 64, top: 98, left: 115 },

  { width: 85, top: 95, left: 104 }, // 目 136
  { width: 71, top: 106, left: 112 },
  { width: 87, top: 105, left: 104 },
  { width: 96, top: 102, left: 103 },
  { width: 94, top: 100, left: 106 },

  { width: 78, top: 98, left: 109 }, // 目 141
  { width: 71, top: 107, left: 112 },
  { width: 94, top: 110, left: 104 },
  { width: 71, top: 108, left: 111 },
  { width: 71, top: 103, left: 111 },

  { width: 73, top: 109, left: 113 }, // 目 146
  { width: 66, top: 116, left: 114 },
  { width: 72, top: 110, left: 112 },
  { width: 96, top: 110, left: 103 },
  { width: 63, top: 116, left: 116 },

  { width: 109, top: 106, left: 102 }, // 目 151
  { width: 77, top: 111, left: 109 },
  { width: 102, top: 98, left: 104 },
  { width: 69, top: 113, left: 117 },
  { width: 77, top: 112, left: 109 },

  { width: 105, top: 100, left: 109 }, // 目 156
  { width: 70, top: 114, left: 113 },
  { width: 77, top: 104, left: 112 },
  { width: 81, top: 113, left: 111 },
  { width: 76, top: 112, left: 111 },

  { width: 73, top: 106, left: 112 }, // 目 161
  { width: 70, top: 102, left: 113 },
  { width: 76, top: 104, left: 113 },
  { width: 79, top: 87, left: 113 },
  { width: 95, top: 105, left: 107 },

  { width: 79, top: 111, left: 110 }, // 目 166
  { width: 72, top: 113, left: 109 },
  { width: 72, top: 110, left: 110 },
  { width: 95, top: 106, left: 102 },
  { width: 81, top: 112, left: 111 },

  { width: 70, top: 110, left: 114 }, // 目 171
  { width: 79, top: 110, left: 110 },
  { width: 85, top: 110, left: 108 },
  { width: 84, top: 111, left: 107 },
  { width: 85, top: 103, left: 106 },

  { width: 75, top: 110, left: 112 }, // 目 176
  { width: 94, top: 105, left: 107 },
  { width: 77, top: 101, left: 107 },
  { width: 76, top: 108, left: 111 },
  { width: 83, top: 96, left: 109 },

  { width: 86, top: 97, left: 102 }, // 目 181
  { width: 73, top: 112, left: 113 },
  { width: 72, top: 117, left: 112 },
  { width: 92, top: 105, left: 106 },
  { width: 77, top: 113, left: 112 },

  { width: 80, top: 111, left: 109 }, // 目 186
  { width: 97, top: 101, left: 104 },
  { width: 84, top: 114, left: 108 },
  { width: 73, top: 108, left: 112 },
  { width: 72, top: 95, left: 112 },
];

// 口
export const mouthAppearanceInMypage: FixedWidthAndPosition[] = [
  { width: 24, top: 138, left: 128 },
  { width: 24, top: 138, left: 128 },
  { width: 20, top: 136, left: 130 },
  { width: 24, top: 136, left: 128 },
  { width: 24, top: 136, left: 128 },
  { width: 12, top: 132, left: 132 },
  { width: 18, top: 132, left: 132 },
  { width: 18, top: 138, left: 132 },
  { width: 12, top: 132, left: 132 },
  { width: 26, top: 138, left: 130 },

  // 以下有料プロダクト
  { width: 26, top: 138, left: 130 },
  { width: 30, top: 126, left: 126 },
  { width: 88, top: 117, left: 108 },
  { width: 16, top: 136, left: 130 },
  { width: 44, top: 140, left: 104 },
  { width: 88, top: 120, left: 108 },
  { width: 88, top: 120, left: 108 },
  { width: 24, top: 138, left: 124 },
  { width: 44, top: 124, left: 121 },
  { width: 94, top: 116, left: 108 },

  { width: 56, top: 118, left: 118 },
  { width: 40, top: 132, left: 122 },
  { width: 40, top: 132, left: 122 },
  { width: 24, top: 134, left: 126 },
  { width: 26, top: 136, left: 126 },
  { width: 26, top: 136, left: 126 },
  { width: 22, top: 134, left: 128 },
  { width: 18, top: 132, left: 132 },
  { width: 18, top: 132, left: 132 },
  { width: 26, top: 136, left: 128 },

  { width: 26, top: 134, left: 128 },
  { width: 30, top: 136, left: 126 },
  { width: 32, top: 134, left: 126 },
  { width: 36, top: 134, left: 124 },
  { width: 30, top: 136, left: 126 },
  { width: 32, top: 134, left: 126 },
  { width: 8, top: 134, left: 134 },
  { width: 20, top: 132, left: 128 },
  { width: 22, top: 134, left: 128 },
  { width: 24, top: 136, left: 128 },

  { width: 26, top: 136, left: 127 },
  { width: 20, top: 132, left: 130 },
  { width: 22, top: 136, left: 130 },
  { width: 36, top: 138, left: 124 },
  { width: 20, top: 133, left: 129 },
  { width: 28, top: 137, left: 129 },
  { width: 30, top: 133, left: 128 },
  { width: 26, top: 133, left: 128 },
  { width: 28, top: 140, left: 128 },
  { width: 40, top: 132, left: 128 },

  { width: 40, top: 130, left: 112 },
  { width: 28, top: 138, left: 127 },
  { width: 18, top: 138, left: 130 },
  { width: 12, top: 138, left: 132 },
  { width: 22, top: 124, left: 128 },
  { width: 92, top: 119, left: 108 },
  { width: 28, top: 128, left: 118 },
  { width: 18, top: 138, left: 132 },
  { width: 22, top: 138, left: 130 },
  { width: 40, top: 132, left: 135 },
  { width: 30, top: 137, left: 129 },
  { width: 10, top: 132, left: 136 },
  { width: 39, top: 141, left: 125 },
  { width: 25, top: 135, left: 126 },
  { width: 35, top: 116, left: 127 },
  { width: 30, top: 136, left: 132 },
  { width: 26, top: 127, left: 125 },
  { width: 26, top: 135, left: 130 },
  { width: 29, top: 120, left: 131 },

  { width: 29, top: 136, left: 131 },
  { width: 29, top: 136, left: 131 },
  { width: 29, top: 136, left: 131 },
  { width: 17, top: 132, left: 138 },
  { width: 15, top: 132, left: 138 },

  { width: 29, top: 136, left: 131 },
  { width: 29, top: 136, left: 131 },
  { width: 29, top: 136, left: 131 },
  { width: 29, top: 136, left: 138 },
  { width: 21, top: 136, left: 137 },

  { width: 18, top: 136, left: 137 }, // 80
  { width: 23, top: 140, left: 134 },
  { width: 29, top: 136, left: 131 },
  { width: 18, top: 130, left: 137 },
  { width: 23, top: 136, left: 130 },

  { width: 31, top: 136, left: 136 }, // 口 85
  { width: 31, top: 141, left: 136 },
  { width: 18, top: 137, left: 142 },
  { width: 18, top: 130, left: 142 },
  { width: 28, top: 135, left: 137 },

  { width: 110, top: 108, left: 71 }, // 口 90
  { width: 93, top: 117, left: 106 },
  { width: 93, top: 117, left: 106 },
  { width: 20, top: 128, left: 136 },
  { width: 20, top: 136, left: 136 },
  { width: 20, top: 136, left: 136 },

  { width: 26, top: 136, left: 136 }, // 口 96
  { width: 37, top: 136, left: 131 },
  { width: 26, top: 133, left: 136 },
  { width: 37, top: 136, left: 131 },
  { width: 28, top: 135, left: 137 },

  { width: 87, top: 125, left: 105 }, // 口 101
  { width: 56, top: 129, left: 98 },
  { width: 56, top: 129, left: 121 },
  { width: 56, top: 129, left: 121 },
  { width: 37, top: 135, left: 126 },

  { width: 54, top: 127, left: 124 }, // 口 106
  { width: 51, top: 125, left: 126 },
  { width: 42, top: 135, left: 129 },
  { width: 32, top: 134, left: 143 },

  { width: 95, top: 137, left: 54 }, // 口 110
  { width: 60, top: 130, left: 96 },
  { width: 24, top: 134, left: 132 },
  { width: 23, top: 131, left: 134 },
  { width: 89, top: 114, left: 109 },

  { width: 22, top: 124, left: 133 }, // 口 115
  { width: 69, top: 131, left: 112 },
  { width: 62, top: 112, left: 86 },
  { width: 31, top: 137, left: 132 },
  { width: 15, top: 135, left: 138 },

  { width: 21, top: 139, left: 134 }, // 口 120
  { width: 27, top: 138, left: 131 },
  { width: 27, top: 137, left: 119 },
  { width: 29, top: 133, left: 132 },
  { width: 13, top: 135, left: 138 },

  { width: 11, top: 139, left: 139 }, // 口 125
  { width: 50, top: 134, left: 119 },
  { width: 56, top: 132, left: 118 },
  { width: 14, top: 136, left: 140 },
  { width: 26, top: 132, left: 134 },

  { width: 62, top: 133, left: 115 }, // 口 130
  { width: 39, top: 118, left: 122 },
  { width: 52, top: 130, left: 102 },
  { width: 15, top: 125, left: 142 },
  { width: 26, top: 119, left: 132 },

  { width: 101, top: 121, left: 98 }, // 口 135
  { width: 14, top: 133, left: 136 },
  { width: 24, top: 132, left: 132 },
  { width: 15, top: 132, left: 139 },
  { width: 18, top: 131, left: 138 },

  { width: 30, top: 135, left: 130 }, // 口 140
  { width: 16, top: 132, left: 137 },
  { width: 82, top: 120, left: 109 },
  { width: 65, top: 124, left: 138 },
  { width: 86, top: 115, left: 107 },

  { width: 44, top: 126, left: 126 }, // 口 145
  { width: 56, top: 136, left: 116 },
  { width: 29, top: 115, left: 127 },
  { width: 94, top: 108, left: 62 },
  { width: 73, top: 125, left: 111 },

  { width: 42, top: 133, left: 126 }, // 口 150
  { width: 70, top: 137, left: 121 },
  { width: 69, top: 135, left: 114 },
  { width: 45, top: 128, left: 135 },
  { width: 25, top: 121, left: 133 },

  { width: 43, top: 134, left: 115 }, // 口 155
  { width: 42, top: 137, left: 118 },
  { width: 97, top: 112, left: 109 },
  { width: 55, top: 125, left: 102 },
  { width: 59, top: 132, left: 122 },
  
  { width: 57, top: 130, left: 116 }, // 口 160
  { width: 35, top: 139, left: 122 },
  { width: 44, top: 130, left: 124 },
  { width: 15, top: 135, left: 140 },
  { width: 11, top: 132, left: 140 },

  { width: 14, top: 139, left: 141 }, // 口 165
  { width: 95, top: 112, left: 107 },
  { width: 34, top: 139, left: 126 },
  { width: 65, top: 135, left: 117 },
  { width: 13, top: 136, left: 140 },
];

// 服
export const clothAppearanceInMypage: FixedHeightAndPosition[] = [
  { height: 130, top: 155, left: 110 },
  { height: 130, top: 155, left: 110 },
  { height: 130, top: 155, left: 110 },
  { height: 130, top: 154, left: 100 },
  { height: 130, top: 154, left: 100 },
  { height: 130, top: 154, left: 100 },
  { height: 128, top: 154, left: 100 },
  { height: 129, top: 155, left: 111 },
  { height: 129, top: 155, left: 111 },
  { height: 129, top: 155, left: 111 },

  // 以下有料プロダクト
  { height: 130, top: 154, left: 100 },
  { height: 130, top: 154, left: 100 },
  { height: 130, top: 154, left: 100 },
  { height: 128, top: 154, left: 100 },
  { height: 126, top: 154, left: 109 },
  { height: 128, top: 155, left: 100 },
  { height: 128, top: 155, left: 100 },
  { height: 128, top: 155, left: 100 },
  { height: 127, top: 153, left: 101 },
  { height: 127, top: 153, left: 101 },

  { height: 127, top: 155, left: 101 },
  { height: 126, top: 155, left: 102 },
  { height: 127, top: 155, left: 101 },
  { height: 126, top: 155, left: 102 },
  { height: 135, top: 151, left: 94 },
  { height: 130, top: 155, left: 111 },
  { height: 133, top: 153, left: 98 },
  { height: 130, top: 152, left: 96 },
  { height: 130, top: 152, left: 96 },
  { height: 126, top: 155, left: 103 },

  { height: 126, top: 155, left: 103 },
  { height: 128, top: 153, left: 90 },
  { height: 128, top: 153, left: 90 },
  { height: 129, top: 153, left: 99 },
  { height: 130, top: 152, left: 92 },
  { height: 132, top: 153, left: 97 },
  { height: 126, top: 155, left: 91 },
  { height: 128, top: 154, left: 90 },
  { height: 130, top: 150, left: 102 },
  { height: 128, top: 154, left: 90 },

  { height: 117, top: 143, left: 90 },
  { height: 132, top: 148, left: 68 },
  { height: 132, top: 150, left: 120 },
  { height: 146, top: 133, left: 97 },
  { height: 126, top: 155, left: 105 },
  { height: 128, top: 152, left: 103 },
  { height: 128, top: 156, left: 107 },
  { height: 128, top: 155, left: 109 },
  { height: 127, top: 154, left: 104 },
  { height: 127, top: 154, left: 104 },

  { height: 145, top: 139, left: 94 },
  { height: 129, top: 154, left: 99 },
  { height: 127, top: 155, left: 99 },
  { height: 129, top: 153, left: 101 },
  { height: 127, top: 155, left: 102 },
  { height: 127, top: 154, left: 111 },
  { height: 127, top: 154, left: 111 },
  { height: 127, top: 154, left: 111 },
  { height: 127, top: 155, left: 85 },
  { height: 127, top: 154, left: 99 },

  { height: 127, top: 155, left: 89 },
  { height: 130, top: 154, left: 93 },
  { height: 141, top: 154, left: 91 },
  { height: 130, top: 149, left: 79 },
  { height: 130, top: 155, left: 89 },
  { height: 132, top: 153, left: 84 },
  { height: 132, top: 153, left: 88 },
  { height: 132, top: 153, left: 101 },
  { height: 132, top: 153, left: 101 },
  { height: 130, top: 156, left: 99 },

  { height: 131, top: 154, left: 99 },
  { height: 132, top: 153, left: 82 },
  { height: 130, top: 154, left: 92 },
  { height: 130, top: 154, left: 92 },
  { height: 130, top: 150, left: 102 },
  { height: 128, top: 152, left: 99 },
  { height: 130, top: 154, left: 81 },
  { height: 130, top: 154, left: 85 },
  { height: 130, top: 154, left: 95 },
  { height: 130, top: 154, left: 97 },

  { height: 130, top: 154, left: 101 },
  { height: 139, top: 154, left: 99 },
  { height: 140, top: 140, left: 77 },
  { height: 134, top: 149, left: 89 },
  { height: 130, top: 152, left: 92 },
  { height: 128, top: 154, left: 93 },
  { height: 129, top: 154, left: 101 },
  { height: 131, top: 151, left: 97 },
  { height: 133, top: 149, left: 93 },
  { height: 128, top: 154, left: 55 },
  { height: 130, top: 150, left: 81 },
  { height: 138, top: 150, left: 96 },
  { height: 138, top: 150, left: 96 },
  { height: 182, top: 85, left: 46 },
  { height: 138, top: 150, left: 106 },
  { height: 136, top: 151, left: 105 },
  { height: 137, top: 151, left: 97 },
  { height: 133, top: 151, left: 96 },
  { height: 133, top: 151, left: 101 },
  { height: 161, top: 123, left: 72 },

  { height: 129, top: 151, left: 98 },
  { height: 133, top: 152, left: 101 },
  { height: 134, top: 151, left: 101 },
  { height: 134, top: 151, left: 101 },
  { height: 142, top: 143, left: 89 }, 

  { height: 134, top: 149, left: 93 }, 
  { height: 130, top: 155, left: 98 }, 
  { height: 130, top: 154, left: 102 }, 
  { height: 131, top: 156, left: 101 }, 
  { height: 128, top: 155, left: 83 }, 
  { height: 128, top: 155, left: 101 }, 

  { height: 134, top: 149, left: 98 }, // 112
  { height: 134, top: 149, left: 98 }, 
  { height: 133, top: 155, left: 107 }, 
  { height: 133, top: 155, left: 107 }, 
  { height: 136, top: 155, left: 111 }, 
  { height: 136, top: 155, left: 111 }, 
  { height: 128, top: 156, left: 119 }, 
  { height: 140, top: 143, left: 77 }, 

  { height: 133, top: 154, left: 98 }, // 服 120
  { height: 133, top: 154, left: 98 }, 
  { height: 136, top: 154, left: 83 }, 
  { height: 136, top: 154, left: 83 }, 
  { height: 136, top: 154, left: 83 }, 
  { height: 140, top: 154, left: 76 }, 
  { height: 140, top: 154, left: 32 }, 
  { height: 140, top: 154, left: 99 }, 

  { height: 133, top: 151, left: 70 }, // 服 128
  { height: 133, top: 151, left: 70 }, 
  { height: 133, top: 154, left: 44 }, 
  { height: 133, top: 154, left: 41 }, 
  { height: 133, top: 154, left: 107 }, 
  { height: 133, top: 154, left: 107 }, 
  { height: 133, top: 154, left: 107 }, 
  { height: 133, top: 154, left: 107 }, 

  { height: 105, top: 149, left: 87 }, // 服 136
  { height: 142, top: 149, left: 103 }, 
  { height: 165, top: 130, left: 47 }, 
  { height: 138, top: 146, left: 52 }, 
  { height: 285, top: 9, left: 2 }, 

  { height: 142, top: 146, left: 73 }, // 服 141
  { height: 136, top: 150, left: 99 }, 
  { height: 124, top: 157, left: 69 }, 
  { height: 176, top: 105, left: 56 }, 
  { height: 176, top: 105, left: 71 }, 

  { height: 142, top: 137, left: 92 }, // 服 146
  { height: 136, top: 150, left: 77 }, 
  { height: 150, top: 131, left: 70 }, 
  { height: 156, top: 138, left: 64 }, 
  { height: 189, top: 91, left: 63 },

  { height: 126, top: 154, left: 90 }, // 服 151
  { height: 145, top: 142, left: 71 },
  { height: 128, top: 146, left: 91 },
  { height: 187, top: 97, left: 35 },
  { height: 130, top: 153,  left: 100 },

  { height: 147, top: 132, left: 81 }, // 服 156
  { height: 132, top: 151, left: 96 },
  { height: 134, top: 146, left: 92 },
  { height: 157, top: 131, left: 45 },
  { height: 130, top: 153,  left: 100 },

  { height: 149, top: 130, left: 91 }, // 服 161
  { height: 132, top: 151, left: 96 },
  { height: 127, top: 153, left: 98 },
  { height: 150, top: 131, left: 81 },
  { height: 129, top: 153,  left: 57 },

  { height: 141, top: 138, left: 67 }, // 服 166
  { height: 201, top: 80, left: 42 },
  { height: 142, top: 139, left: 65 },
  { height: 179, top: 100, left: 61 },
  { height: 249, top: 30,  left: 78 },

  { height: 200, top: 81, left: 50 }, // 服 171
  { height: 128, top: 153, left: 64 },
  { height: 147, top: 134, left: 65 },
  { height: 141, top: 140, left: 44 },
  { height: 206, top: 76,  left: 40 },

  { height: 135, top: 145, left: 73 }, // 服 176
  { height: 179, top: 102, left: 37 },
  { height: 130, top: 149, left: 65 },
  { height: 137, top: 148, left: 69 },
  { height: 138, top: 145,  left: 59 },

  { height: 128, top: 153, left: 109 }, // 服 181
  { height: 129, top: 153, left: 53 },
  { height: 126, top: 155, left: 69 },
  { height: 125, top: 155, left: 100 },
  { height: 206, top: 90,  left: 58 },

  { height: 143, top: 136, left: 50 }, // 服 186
  { height: 195, top: 85, left: 90 },
  { height: 123, top: 161, left: 83 },
  { height: 151, top: 132, left: 80 },
  { height: 126, top: 155, left: 82 },

  { height: 134, top: 150, left: 72 }, // 服 191
  { height: 146, top: 134, left: 47 },
  { height: 128, top: 153, left: 94 },
  { height: 108, top: 152, left: 84 },
  { height: 160, top: 123,  left: 20 },

  { height: 139, top: 141, left: 54 }, // 服 196
  { height: 100, top: 156, left: 81 },
  { height: 128, top: 153, left: 75 },
  { height: 195, top: 92, left: 39 },
  { height: 131, top: 149,  left: 73 },

  { height: 168, top: 113, left: 45 }, // 服 201
  { height: 144, top: 138, left: 57 },
  { height: 181, top: 99, left: 36 },
  { height: 131, top: 153, left: 112 },
  { height: 159, top: 121, left: 48 },

  { height: 177, top: 104, left: 57 }, // 服 206
  { height: 129, top: 153, left: 98 },
  { height: 161, top: 136, left: 73 },
  { height: 137, top: 143, left: 82 },
  { height: 144, top: 130, left: 48 },
];

// 髪
export const hairAppearanceInMypage: FixedWidthAndPosition[] = [
  { width: 118, top: 33, left: 100 },
  { width: 118, top: 35, left: 100 },
  { width: 115, top: 35, left: 103 },
  { width: 178, top: 37, left: 76 },
  { width: 120, top: 33, left: 96 },
  { width: 120, top: 35, left: 100 },
  { width: 118, top: 36, left: 100 },
  { width: 108, top: 39, left: 104 },
  { width: 112, top: 38, left: 102 },
  { width: 117, top: 34, left: 101 },

  { width: 114, top: 36, left: 100 },
  { width: 132, top: 39, left: 92 },
  { width: 128, top: 36, left: 98 },
  { width: 136, top: 40, left: 100 },
  { width: 116, top: 37, left: 100 },
  { width: 118, top: 37, left: 100 },
  { width: 124, top: 35, left: 92 },
  { width: 119, top: 35, left: 98 },
  { width: 111, top: 35, left: 105 },
  { width: 134, top: 35, left: 86 },

  // 以下有料プロダクト
  { width: 132, top: 39, left: 94 },
  { width: 114, top: 36, left: 102 },
  { width: 109, top: 36, left: 102 },
  { width: 127, top: 35, left: 95 },
  { width: 111, top: 37, left: 103 },
  { width: 111, top: 37, left: 102 },
  { width: 122, top: 35, left: 95 },
  { width: 108, top: 21, left: 102 },
  { width: 122, top: 35, left: 106 },
  { width: 115, top: 36, left: 104 },

  { width: 125, top: 40, left: 101 },
  { width: 120, top: 24, left: 98 },
  { width: 108, top: 35, left: 105 },
  { width: 116, top: 36, left: 94 },
  { width: 100, top: 18, left: 106 },
  { width: 130, top: 38, left: 92 },
  { width: 130, top: 38, left: 95 },
  { width: 112, top: 35, left: 103 },
  { width: 112, top: 35, left: 103 },
  { width: 122, top: 36, left: 98 },

  { width: 119, top: 28, left: 101 },
  { width: 144, top: 36, left: 90 },
  { width: 130, top: 18, left: 106 },
  { width: 120, top: 39, left: 103 },
  { width: 128, top: 33, left: 95 },
  { width: 110, top: 33, left: 102 },
  { width: 111, top: 37, left: 104 },
  { width: 107, top: 33, left: 105 },
  { width: 116, top: 33, left: 96 },
  { width: 90, top: 32, left: 138 },

  { width: 156, top: 39, left: 88 },
  { width: 102, top: 36, left: 107 },
  { width: 102, top: 38, left: 111 },
  { width: 125, top: 38, left: 99 },
  { width: 116, top: 36, left: 98 },
  { width: 128, top: 16, left: 96 },
  { width: 128, top: 18, left: 96 },
  { width: 124, top: 28, left: 92 },
  { width: 126, top: 32, left: 96 },
  { width: 110, top: 36, left: 102 },

  { width: 110, top: 36, left: 102 },
  { width: 132, top: 34, left: 89 },
  { width: 120, top: 39, left: 99 },
  { width: 118, top: 29, left: 99 },
  { width: 154, top: 37, left: 84 },
  { width: 109, top: 30, left: 101 },
  { width: 125, top: 32, left: 97 },
  { width: 114, top: 35, left: 98 },
  { width: 133, top: 34, left: 89 },
  { width: 133, top: 33, left: 96 },

  { width: 115, top: 34, left: 98 },
  { width: 128, top: 31, left: 95 },
  { width: 132, top: 31, left: 88 },
  { width: 122, top: 30, left: 96 },
  { width: 160, top: 25, left: 102 },

  { width: 144, top: 35, left: 87 },
  { width: 133, top: 35, left: 94 },
  { width: 143, top: 27, left: 87 },
  { width: 122, top: 31, left: 88 },
  { width: 131, top: 32, left: 94 },

  { width: 120, top: 24, left: 102 },
  { width: 144, top: 35, left: 87 },
  { width: 136, top: 37, left: 91 },
  { width: 115, top: 37, left: 98 },
  { width: 144, top: 35, left: 73 },

  { width: 134, top: 20, left: 91 }, // 86
  { width: 134, top: 20, left: 98 },
  { width: 145, top: 34, left: 89 },
  { width: 138, top: 35, left: 90 },
  { width: 128, top: 34, left: 98 },

  { width: 134, top: 20, left: 91 }, // 髪91
  { width: 134, top: 20, left: 98 },
  { width: 145, top: 34, left: 89 },
  { width: 138, top: 35, left: 90 },
  { width: 128, top: 34, left: 98 },

  { width: 132, top: 28, left: 90 },  // 髪96
  { width: 132, top: 37, left: 90 }, 
  { width: 176, top: 35, left: 74 }, 
  { width: 139, top: 1, left: 89 }, 
  { width: 134, top: 26, left: 91 }, 

  { width: 153, top: 35, left: 79 },  // 髪 101 
  { width: 164, top: 3, left: 69 }, 
  { width: 153, top: 24, left: 85 }, 
  { width: 114, top: 29, left: 106 }, 
  { width: 132, top: 35, left: 90 }, 

  { width: 225, top: 35, left: 40 },  // 髪 106
  { width: 170, top: 8, left: 65 }, 
  { width: 234, top: 18, left: 44 }, 
  { width: 129, top: 37, left: 93 }, 
  { width: 154, top: 24, left: 79 }, 

  { width: 219, top: 35, left: 45 },  // 髪 111
  { width: 142, top: 38, left: 88 }, 
  { width: 120, top: 37, left: 101 }, 
  { width: 124, top: 37, left: 93 }, 
  { width: 166, top: 40, left: 70 }, 

  { width: 138, top: 36, left: 101 }, // 髪116
  { width: 157, top: 13, left: 90 },
  { width: 133, top: 39, left: 88 },
  { width: 122, top: 16, left: 100 },
  { width: 209, top: 23, left: 52 },

  { width: 175, top: 39, left: 76 }, // 髪121
  { width: 127, top: 37, left: 90 },
  { width: 153, top: 40, left: 85 },
  { width: 145, top: 34, left: 86 },
  { width: 168, top: 26, left: 95 },

  { width: 144, top: 39, left: 94 }, // 髪126
  { width: 204, top: 16, left: 70 },
  { width: 146, top: 37, left: 88 },
  { width: 139, top: 39, left: 94 },
  { width: 159, top: 36, left: 80 },

  { width: 113, top: -9, left: 102 }, // 髪131
  { width: 190, top: -2, left: 62 },
  { width: 146, top: 37, left: 88 },
  { width: 133, top: 25, left: 92 },
  { width: 209, top: 38, left: 55 },

  { width: 147, top: 17, left: 78 }, // 髪136
  { width: 174, top: 9, left: 71 },
  { width: 156, top: 37, left: 66 },
  { width: 203, top: 13, left: 57 },
  { width: 133, top: 36, left: 97 },

  { width: 183, top: 22, left: 67 }, // 髪141
  { width: 138, top: 31, left: 89 },
  { width: 144, top: 22, left: 93 },
  { width: 144, top: 35, left: 83 },
  { width: 133, top: 36, left: 89 },

  { width: 224, top: 34, left: 45 }, // 髪146
  { width: 171, top: 9, left: 74 },
  { width: 161, top: 33, left: 79 },
  { width: 118, top: 14, left: 99 },
  { width: 132, top: 38, left: 87 },

  { width: 198, top: 39, left: 60 }, // 髪151
  { width: 148, top: 39, left: 83 },
  { width: 206, top: 28, left: 77 },
  { width: 135, top: 23, left: 93 },
  { width: 149, top: 36, left: 85 },

  { width: 120, top: 18, left: 99 }, // 髪156
  { width: 163, top: 10, left: 83 },
  { width: 193, top: 32, left: 63 },
  { width: 124, top: 34, left: 100 },
  { width: 171, top: -32, left: 66 },

  { width: 144, top: 23, left: 84 }, // 髪161
  { width: 141, top: 32, left: 88 },
  { width: 131, top: 26, left: 92 },
  { width: 154, top: 36, left: 94 },
  { width: 193, top: 31, left: 66 },

  { width: 153, top: 66, left: 80 }, // 髪166
  { width: 184, top: 18, left: 69 },
  { width: 153, top: 29, left: 83 },
  { width: 156, top: 31, left: 83 },
  { width: 56, top: 12, left: 129 },

  { width: 122, top: 36, left: 101 }, // 髪171
  { width: 124, top: 38, left: 96 },
  { width: 188, top: 36, left: 68 },
  { width: 186, top: 35, left: 69 },
  { width: 156, top: 32, left: 79 },  
];

// 帽子
export const hatAppearanceInMypage: FixedWidthAndPosition[] = [
  { width: 116, top: 26, left: 98 },
  { width: 116, top: 26, left: 98 },
  { width: 118, top: 22, left: 99 },
  { width: 118, top: 22, left: 99 },
  { width: 130, top: 24, left: 84 },
  { width: 130, top: 24, left: 84 },
  // 以下有料プロダクト
  { width: 132, top: 20, left: 96 },
  { width: 132, top: 20, left: 96 },
  { width: 152, top: 12, left: 84 },
  { width: 104, top: 48, left: 105 },

  { width: 134, top: 33, left: 88 },
  { width: 108, top: 48, left: 108 },
  { width: 122, top: 48, left: 97 },
  { width: 125, top: 40, left: 94 },
  { width: 125, top: 40, left: 94 },
  { width: 104, top: 48, left: 100 },
  { width: 104, top: 48, left: 100 },
  { width: 138, top: 2, left: 95 },
  { width: 138, top: 2, left: 95 },
  { width: 108, top: 40, left: 96 },

  { width: 108, top: 40, left: 96 },
  { width: 82, top: 48, left: 112 },
  { width: 152, top: 18, left: 98 },
  { width: 114, top: 22, left: 104 },
  { width: 198, top: 20, left: 59 },
  { width: 130, top: 30, left: 96 },
  { width: 144, top: 2, left: 92 },
  { width: 132, top: 20, left: 97 },
  { width: 120, top: 0, left: 95 },
  { width: 100, top: 10, left: 106 },

  { width: 130, top: 52, left: 90 },
  { width: 157, top: -4, left: 90 },
  { width: 164, top: 55, left: 71 },
  { width: 142, top: 9, left: 99 },
  { width: 126, top: 24, left: 90 },
  { width: 64, top: 8, left: 99 },
  { width: 118, top: 19, left: 98 },
  { width: 54, top: 37, left: 160 },
  { width: 60, top: 41, left: 160 },
  { width: 128, top: 66, left: 103 },

  { width: 126, top: 66, left: 91 },
  { width: 100, top: 62, left: 107 },
  { width: 46, top: 60, left: 158 },
  { width: 140, top: 23, left: 89 },
  { width: 160, top: 15, left: 78 },
  { width: 130, top: 8, left: 94 },
  { width: 130, top: 12, left: 84 },
  { width: 160, top: 0, left: 80 },
  { width: 80, top: 21, left: 122 },
  { width: 110, top: 46, left: 100 },

  { width: 144, top: 1, left: 90 },
  { width: 110, top: 26, left: 100 },
  { width: 119, top: 0, left: 99 },
  { width: 116, top: 26, left: 98 },
  { width: 124, top: 32, left: 98 },
  { width: 122, top: 28, left: 100 },
  { width: 140, top: 8, left: 107 },
  { width: 188, top: 24, left: 65 },
  { width: 80, top: 13, left: 140 },
  { width: 140, top: 6, left: 92 },

  { width: 126, top: 16, left: 96 },
  { width: 140, top: 12, left: 94 },
  { width: 152, top: 18, left: 84 },
  { width: 132, top: 11, left: 90 },
  { width: 174, top: 11, left: 78 },
  { width: 140, top: 20, left: 78 },
  { width: 170, top: -4, left: 72 },
  { width: 154, top: -10, left: 82 },
  { width: 192, top: 18, left: 66 },
  { width: 116, top: 20, left: 110 },

  { width: 126, top: 12, left: 90 },
  { width: 97, top: 10, left: 110 },
  { width: 112, top: 6, left: 100 },
  { width: 116, top: 40, left: 102 },
  { width: 170, top: 10, left: 73 },
  { width: 119, top: 0, left: 97 },
  { width: 90, top: 36, left: 100 },
  { width: 87, top: -7, left: 123 },
  { width: 70, top: -9, left: 118 },

  { width: 173, top: -4, left: 85 },
  { width: 158, top: -1, left: 75 },
  { width: 160, top: -1, left: 75 },
  { width: 153, top: 9, left: 71 },
  { width: 171, top: 0, left: 77 },

  { width: 164, top: 23, left: 74 },
  { width: 104, top: 38, left: 99 },
  { width: 101, top: 40, left: 104 },
  { width: 123, top: 34, left: 90 },
  { width: 118, top: 30, left: 107 },

  { width: 134, top: 23, left: 79 },
  { width: 134, top: 2, left: 87 },
  { width: 141, top: 5, left: 87 },
  { width: 114, top: 13, left: 102 },
  { width: 138, top: 13, left: 94 },

  { width: 116, top: 18, left: 102 }, // 95
  { width: 130, top: 75, left: 102 },
  { width: 126, top: 25, left: 87 },
  { width: 154, top: -18, left: 72 },
  { width: 162, top: 19, left: 71 },

  { width: 145, top: 25, left: 83 }, // 帽子 100
  { width: 145, top: 25, left: 83 },
  { width: 93, top: 31, left: 105 },
  { width: 118, top: 28, left: 99 },
  { width: 118, top: 20, left: 94 },

  { width: 64, top: 37, left: 172 }, // 帽子 105
  { width: 64, top: 47, left: 166 },
  { width: 106, top: 47, left: 100 },
  { width: 106, top: 47, left: 100 },
  { width: 77, top: 20, left: 138 },
  { width: 77, top: 20, left: 138 },
  { width: 103, top: 61, left: 101},

  { width: 112, top: -43, left: 109 }, // 帽子 112
  { width: 126, top: 23, left: 97 },
  { width: 146, top: 27, left: 86 },
  { width: 146, top: 1, left: 81 },
  { width: 118, top: 20, left: 94 },

  { width: 146, top: 34, left: 81 }, // 帽子 117
  { width: 126, top: 23, left: 97 },
  { width: 179, top: 31, left: 67 },
  { width: 99, top: -4, left: 122 },
  { width: 165, top: -12, left: 75 },

  { width: 159, top: 63, left: 85 }, // 帽子 122
  { width: 165, top: 14, left: 77 },
  { width: 189, top: 0, left: 67 },
  { width: 99, top: 39, left: 105 },
  { width: 135, top: -12, left: 85 },


  { width: 153, top: 13, left: 85 }, //帽子 127
  { width: 112, top: -14, left: 101 },
  { width: 124, top: 16, left: 101 },
  { width: 110, top: 37, left: 95 },
  { width: 143, top: 15, left: 84 },

  { width: 33, top: 6, left: 140 }, //帽子 132
  { width: 161, top: 15, left: 100 },
  { width: 138, top: 8, left: 89 },
  { width: 110, top: 12, left: 95 },
  { width: 162, top: 22, left: 88 },

  { width: 123, top: 8, left: 92 }, //帽子 137
  { width: 130, top: 31, left: 111 },
  { width: 128, top: 59, left: 115 },
  { width: 125, top: 12, left: 88 },
  { width: 44, top: 18, left: 137 },

  { width: 115, top: 5, left: 97 }, //帽子 142
  { width: 75, top: 31, left: 145 },
  { width: 98, top: -13, left: 162 },
  { width: 95, top: 3, left: 68 },
  { width: 164, top: -35, left: 69 },

  { width: 139, top: -8, left: 88 }, //帽子 147
  { width: 165, top: 9, left: 84 },
  { width: 187, top: 8, left: 73 },
  { width: 163, top: 22, left: 73 },
  { width: 216, top: -13, left: 49 },

  { width: 133, top: 37, left: 100 }, //帽子 152
  { width: 140, top: -44, left: 90 },
  { width: 167, top: 18, left: 81 },
  { width: 141, top: 22, left: 85 },
  { width: 155, top: 23, left: 91 },

  { width: 143, top: -2, left: 82 }, //帽子 157
  { width: 140, top: -4, left: 80 },
  { width: 168, top: 19, left: 67 },
  { width: 134, top: 4, left: 89 },
  { width: 128, top: -1, left: 94 },

  { width: 38, top: 58, left: 182 }, //帽子 162
  { width: 128, top: -8, left: 89 },
  { width: 140, top: 29, left: 88 },
  { width: 211, top: -18, left: 55 },
  { width: 178, top: 11, left: 67 },

  { width: 142, top: 25, left: 82 }, //帽子 167
  { width: 242, top: 3, left: 38 },
  { width: 120, top: 45, left: 102 },
  { width: 102, top: -7, left: 104 },
  { width: 118, top: -5, left: 97 },

  { width: 138, top: -16, left: 86 }, //帽子 172
  { width: 145, top: 1, left: 84 },
  { width: 123, top: 23, left: 95 },
  { width: 76, top: 35, left: 164 },
  { width: 138, top: 18, left: 86 },

  { width: 48, top: 29, left: 153 }, //帽子 177
  { width: 81, top: 18, left: 126 },
  { width: 111, top: 16, left: 110 },
  { width: 113, top: 19, left: 118 },
  { width: 171, top: 12, left: 75 },

  { width: 131, top: 29, left: 93 }, // 帽子 182
  { width: 170, top: 28, left: 85 },
  { width: 174, top: 4, left: 70 },
  { width: 115, top: 19, left: 102 },
  { width: 176, top: 1, left: 68 },
];
