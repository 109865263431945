// 毎月追加されるアバターパーツのうち、どこからが新しく追加されたパーツかを判断する数値(product_number)を管理する定数

// 目
export const newEyeIndex = 186;
// 口
export const newMouthIndex = 165;
// 服
export const newClothIndex = 206;
// 髪
export const newHairIndex = 171;
// 帽子
export const newHatIndex = 182;
